.table {
  margin-bottom: 0;
  thead {
    text-align: left;
  }

  tr {
    &.rowHover:hover {
      background: $very-light-blue;
      cursor: pointer;
    }
  }
}
