@import "./orderBuilder/orderBuilder.scss";
@import "./orderProcessor/batchSerialNumbers/batchSerialNumber";
@import "./epicor/epicor.scss";
@import "./slack/slack.scss";
@import "./displays/displays.scss";

.appBox {
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    padding: 50px;
    min-height: 120px;
    min-width: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px;
  }
  h3 {
    font-size: 1.7em;
    margin-bottom: 0;
  }
  .info {
    flex-grow: 1;
  }
}

.disabled_app {
  .icon {
    opacity: 0.5;
  }
  border-top: 6px solid #b1b1b1 !important;
}

.disabled {
  cursor: not-allowed;
}

#email_app {
  .icon {
    background-image: url(./images/email.svg);
  }
  a {
    color: #b56300;
  }
  border-top: 6px solid #b56300;
}

#variance_app {
  .icon {
    background-image: url(./images/variance.svg);
  }
  a {
    color: #b50000;
  }
  border-top: 6px solid #b50000;
}

#csv_app {
  .icon {
    background-image: url(./images/csv.svg);
  }
  a {
    color: #444b53;
  }
  border-top: 6px solid #444b53;
}

#cycle_counts_app {
  .icon {
    background-image: url(./images/cycleCount.svg);
  }
  a {
    color: #089f00;
  }
  border-top: 6px solid #089f00;
}

#order_builder_app {
  .icon {
    background-image: url(./images/orderBuilder.svg);
  }
  a {
    color: #ff633d;
  }
  border-top: 6px solid #ff633d;
}

#order_processor_app {
  .icon {
    background-image: url(./images/orderProcessor.svg);
  }
  a {
    color: #c85e25;
  }
  border-top: 6px solid #c85e25;
}

#pdf_app {
  .icon {
    background-image: url(./images/pdf.svg);
  }
  a {
    color: #73373e;
  }
  border-top: 6px solid #73373e;
}

#xlsx_app {
  .icon {
    background-image: url(./images/xlsx.svg);
  }
  border-top: 6px solid #234599;
}

#philips_hue_app {
  .icon {
    background-image: url(./images/hue.svg);
  }
  a {
    color: #075bd7;
  }
  border-top: 6px solid #075bd7;
}

#custom_displays_app,
#displays_app {
  .icon {
    background-image: url(./images/customDisplays.svg);
  }
  border-top: 6px solid #a9b1af;
}

#sms_app {
  .icon {
    background-image: url(./images/sms.svg);
  }
  border-top: 6px solid $light-grey;
}

#epicor_app {
  .icon {
    background-image: url(./images/epicor.svg);
  }
  a {
    color: #00bce9;
  }
  border-top: 6px solid #00bce9;
}

#zebra_app {
  .icon {
    background-image: url(./images/zebra.svg);
  }
  a {
    color: #ffce00;
  }
  border-top: 6px solid #ffce00;
}
#slack_app {
  .icon {
    background-image: url(./images/slack.svg);
  }
  a {
    color: #4a154b;
  }
  border-top: 6px solid #4a154b;
}
#data_push_app {
  .icon {
    background-image: url(./images/dataPush.svg);
  }
  a {
    color: #76a0f8;
  }
  border-top: 6px solid #76a0f8;
}
.app,
.integration {
  margin: 0 -25px;
  padding: 0 25px;
  border-top-width: 2px !important;

  .controls {
    display: flex;
    background-color: white;
    margin: 0 -25px 25px;
    padding: 7px 25px;
    border-bottom: 1px solid $light-grey;
    .title {
      flex-grow: 1;
      margin: 0;
      .separator,
      a,
      h1 {
        font-size: 1.2rem;
        display: inline-block;
        font-weight: 500;
        margin: 5px;
      }
      .separator {
        padding: 0 10px;
      }
    }
    .controlButtons {
      margin: 5px 0;
    }

    .icon {
      min-height: 32px;
      min-width: 32px;
      margin-right: 10px;

      background-repeat: no-repeat;
      background-position: center 5px;
      background-size: auto 32px;
    }
  }
}
